import { loggerLink } from '@trpc/client'

import superjson, { registerCustom } from 'superjson'
import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'
import { Decimal } from 'decimal.js'

import type { AppRouter } from '~/server/trpc/routers'

registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: v => v.toJSON(),
    deserialize: v => new Decimal(v),
  },
  'Decimal',
)

// import type { TRPCLink } from '@trpc/client'
// import { TRPCClientError, loggerLink } from '@trpc/client'
// import { observable } from '@trpc/server/observable'

// const authLink: TRPCLink<AppRouter> = () => {
//   return ({ next, op }) => {
//     return observable((observer) => {
//       const unsubscribe = next(op).subscribe({
//         next(value) {
//           observer.next(value)
//         },
//         error(err) {
//           if (err instanceof TRPCClientError) {
//             if (err.data?.code === 'UNAUTHORIZED') {
//               // await navigateTo('/login')
//             }
//           }

//           observer.error(err)
//         },
//         complete() {
//           observer.complete()
//         },
//       })
//       return unsubscribe
//     })
//   }
// }

export default defineNuxtPlugin(() => {
  const trpc = createTRPCNuxtClient<AppRouter>({
    transformer: superjson,
    links: [
      loggerLink({
        enabled: (opts) => {
          if (import.meta.env.DEV) {
            return !import.meta.env.SSR || (opts.direction === 'down' && opts.result instanceof Error)
          }

          return false
        },
      }),
      httpBatchLink(),
    ],
  })

  return {
    provide: {
      trpc,
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.on('vite:afterUpdate', () => {
    registerCustom<Decimal, string>(
      {
        isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
        serialize: v => v.toJSON(),
        deserialize: v => new Decimal(v),
      },
      'Decimal',
    )
  })
}
