import { fb, ga } from '../utils_analytics'

export default defineNuxtPlugin(() => {
  const fbq: (...args: Array<unknown>) => void = fb()
  const gtag: (...args: Array<unknown>) => void = ga()

  // global
  fbq('init', '295277838754679')
  fbq('track', 'PageView')

  // global
  gtag('js', new Date())
  gtag('config', 'AW-620905753')

  return {
    provide: {
      analytics: {
        fbq,
        gtag,
      },
    },
  }
})
