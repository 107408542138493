import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBankTransferDetails, LazyCreateEmailConfirmation, LazyCreateGiftOrder, LazyMagicLink, LazyPasswordReset, LazyPerformAction, LazyThankYouOrder, LazyTutorSurvey, LazyWelcome, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["BankTransferDetails", LazyBankTransferDetails],
["CreateEmailConfirmation", LazyCreateEmailConfirmation],
["CreateGiftOrder", LazyCreateGiftOrder],
["MagicLink", LazyMagicLink],
["PasswordReset", LazyPasswordReset],
["PerformAction", LazyPerformAction],
["ThankYouOrder", LazyThankYouOrder],
["TutorSurvey", LazyTutorSurvey],
["Welcome", LazyWelcome],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
