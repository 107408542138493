/* eslint-disable no-console */

// NOTE: the plugin is for the client side only
// removing the `.client` from the file name will execute this file on the server causing the app do perpetually crash.

class Katanga {
  greetings() {}
}

/**
 * The cmd function is used to request user input
 */
function cmd(...args: any[]) {
  if (typeof args[0] === 'string') {
    //
  }
}

/**
 * The global `katanga` object, used for debugging and general utility
 */
const katanga = new Katanga()

// Create a global utility to send email errors
Object.defineProperty(window, 'telldev', {
  writable: false,
  value: () => {
    const emailAddress = 'dev@scuolaperricchi.com'
    const subject = 'Reporting an Error'
    const body = 'Hello,\n\nI found a bug and would like to report it.\n\n[Please describe the error here]\n\nThank you!'

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`

    window.open(mailtoLink, '_blank', 'width=500,height=400')
  },
})

// Create the global `katanga` object
Object.defineProperty(window, 'katanga', {
  writable: false,
  value: katanga,
})

// Create the global `cmd` function
Object.defineProperty(window, 'cmd', {
  writable: false,
  value: cmd,
})

// easter egg
console.log(
  '%cScuola per Ricchi',
  `
font-size: 24px;
color: white;
text-align: center;
padding: 10px 15px;
width: 100%;
border-radius: 20px;
background: #3a7bd5;
background: -webkit-linear-gradient(to right, #3a6073, #3a7bd5);
background: linear-gradient(to right, #3a6073, #3a7bd5);
`,
)

// Self-XSS warning message, https://en.wikipedia.org/wiki/Self-XSS
console.log(
  '%cWARNING\n%cExecuting untrusted code in the console can pose a serious security risk. Never copy-paste any code here unless you know exactly what it does and trust the source. Keep yourself safe by being cautious and mindful of your actions in the console.\nHave fun coding!',
  `
font-size: 24px;
color: yellow;
background: red;
padding: 0 10px;
`,
  `
font-size: 14px;
font-weight: bold;
`,
)

/**
 * This plugin is responsible of setting up the environment for the client side, it is unused for now.
 */
export default defineNuxtPlugin(() => {})
