import decimal_payload_tHqTj3yjZn from "/vercel/path0/apps/app/plugins/decimal-payload.ts";
import revive_payload_client_G1KWdHc3Bv from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_UHbo89XlZ1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_M8XEAj22EY from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_aK2KZGbNhq from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_PWCMME5QjZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_sOezd3s34F from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_teutGWm6Nn from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_q39IQ33IH7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.1_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_oui0HqkQaz from "/vercel/path0/node_modules/.pnpm/nuxt-zod-i18n@1.8.0_rollup@4.18.0/node_modules/nuxt-zod-i18n/dist/runtime/plugin.mjs";
import i18n_scUw4hEEPg from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.0_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_0ZLWA3G2s5 from "/vercel/path0/node_modules/.pnpm/@fixers+nuxt-authjs@0.0.20_nodemailer@6.9.13_rollup@4.18.0/node_modules/@fixers/nuxt-authjs/dist/runtime/plugin.client.mjs";
import chunk_reload_client_aTIqugJ5n1 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_boot_client_5Dfko1A7cm from "/vercel/path0/apps/app/plugins/0.boot.client.ts";
import _2_analytics_WUMkQbWQEW from "/vercel/path0/apps/app/plugins/2.analytics.ts";
import mdit_2z6Su46k5D from "/vercel/path0/apps/app/plugins/mdit.ts";
import notiwind_qYGIDwLcpw from "/vercel/path0/apps/app/plugins/notiwind.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/apps/app/plugins/sentry.client.ts";
import trpc_client_sNkZYQPPl5 from "/vercel/path0/apps/app/plugins/trpc-client.ts";
export default [
  decimal_payload_tHqTj3yjZn,
  revive_payload_client_G1KWdHc3Bv,
  unhead_UHbo89XlZ1,
  router_M8XEAj22EY,
  payload_client_aK2KZGbNhq,
  check_outdated_build_client_PWCMME5QjZ,
  plugin_vue3_sOezd3s34F,
  components_plugin_KR1HBZs4kY,
  prefetch_client_teutGWm6Nn,
  plugin_client_q39IQ33IH7,
  plugin_oui0HqkQaz,
  i18n_scUw4hEEPg,
  plugin_client_0ZLWA3G2s5,
  chunk_reload_client_aTIqugJ5n1,
  _0_boot_client_5Dfko1A7cm,
  _2_analytics_WUMkQbWQEW,
  mdit_2z6Su46k5D,
  notiwind_qYGIDwLcpw,
  sentry_client_shVUlIjFLk,
  trpc_client_sNkZYQPPl5
]