// Facebook/Meta have a terrible API...
// export function fb() {
//   if (typeof window !== 'undefined') {
//     const f = window

//     if (f.fbq) {
//       return
//     }

//     const n = f.fbq = function () {
//       // eslint-disable-next-line prefer-spread, prefer-rest-params
//       n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
//     }

//     if (!f._fbq) {
//       f._fbq = n
//     }

//     n.push = n
//     n.loaded = true
//     n.version = '2.0'
//     n.queue = []

//     return f.fbq
//   }

//   return () => {}
// }

export function fb() {
  if (typeof window !== 'undefined') {
    /* eslint-disable prefer-rest-params, prefer-spread, no-unused-expressions */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) {
        return
      }
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) {
        f._fbq = n
      }
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    )
    /* eslint-enable */

    return fbq
  }

  return () => {}
}

export function ga() {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    const gtag = () => {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments)
    }

    return gtag
  }

  return () => {}
}
