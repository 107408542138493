import Mdit from 'markdown-it'

export default defineNuxtPlugin(() => {
  const markdownit = new Mdit({
    html: false,
    xhtmlOut: false,
    breaks: true,
    langPrefix: 'language-',
    linkify: true,
    typographer: true,
    quotes: '“”‘’',
  })

  return {
    provide: {
      mdit: markdownit,
    },
  }
})
