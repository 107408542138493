import * as Sentry from '@sentry/vue'
import { TRPCClientError } from '@trpc/client'
import { FetchError } from 'ofetch'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }

  const { status, data } = useAuth()
  watch([status, data], ([status, data]) => {
    const user = data?.user as any
    if (status === 'authenticated' && user) {
      Sentry.setUser({ id: user.id, email: user.email })
    }
    else {
      Sentry.setUser(null)
    }
  }, {
    immediate: true,
  })

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [Sentry.browserTracingIntegration({
      router,
    })],
    beforeSend(event, hint) {
      const error: any = hint.originalException

      // ignore 404 errors
      if (error && error.response && error.response.status === 404) {
        return null
      }

      // ignore 404 errors
      if (error instanceof FetchError && error.statusCode === 404) {
        return null
      }

      // ignore unauthorized errors
      if (error instanceof TRPCClientError && error.data?.code === 'UNAUTHORIZED') {
        return null
      }

      // Ignore errors from dynamic imports
      if (error instanceof TypeError && (
        error.message.includes('Failed to fetch dynamically imported module')
        || error.message.includes('error loading dynamically imported module')
        || error.message.includes('Importing a module script failed.')
      )) {
        return null
      }

      return event
    },

    tracesSampleRate: 0.1,
    tracePropagationTargets: ['localhost', 'app.scuolaperricchi.com'],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  })
})
