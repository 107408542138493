import { default as __vid_45player_454145172x1b2sCVaWPMeta } from "/vercel/path0/apps/app/pages/__vid-player-4145172.vue?macro=true";
import { default as _64_91username_93nYiabfff0XMeta } from "/vercel/path0/apps/app/pages/@[username].vue?macro=true";
import { default as indexmnGud9ftpqMeta } from "/vercel/path0/apps/app/pages/academy/[id]/[[lessonShortCode]]/index.vue?macro=true";
import { default as indexwsXC69kIz1Meta } from "/vercel/path0/apps/app/pages/academy/academy-free/[[lessonShortCode]]/index.vue?macro=true";
import { default as indexBuJIBdQObBMeta } from "/vercel/path0/apps/app/pages/academy/index.vue?macro=true";
import { default as indexQRck1ME8RmMeta } from "/vercel/path0/apps/app/pages/audiobooks/index.vue?macro=true";
import { default as indextu4CDtYlcqMeta } from "/vercel/path0/apps/app/pages/bookmarks/index.vue?macro=true";
import { default as indexV73INH7A7JMeta } from "/vercel/path0/apps/app/pages/checkout/[id]/index.vue?macro=true";
import { default as indexny6S7OVUSLMeta } from "/vercel/path0/apps/app/pages/community/[postSlug]/index.vue?macro=true";
import { default as indexVMmtOk7YyQMeta } from "/vercel/path0/apps/app/pages/community/index.vue?macro=true";
import { default as company_45valueskPLuH9F0aZMeta } from "/vercel/path0/apps/app/pages/company-values.vue?macro=true";
import { default as cookie_45policyw0FhKBnoy0Meta } from "/vercel/path0/apps/app/pages/cookie-policy.vue?macro=true";
import { default as indexXK0uj0kBiVMeta } from "/vercel/path0/apps/app/pages/course/[id]/[[lessonShortCode]]/index.vue?macro=true";
import { default as email_45confirmationHBxEjS2CxmMeta } from "/vercel/path0/apps/app/pages/email-confirmation.vue?macro=true";
import { default as forgot_45passwordlKQlRzReh0Meta } from "/vercel/path0/apps/app/pages/forgot-password.vue?macro=true";
import { default as indexc52TVkaZdsMeta } from "/vercel/path0/apps/app/pages/leaderboard/index.vue?macro=true";
import { default as indexsPG2OfC3nIMeta } from "/vercel/path0/apps/app/pages/lessons/index.vue?macro=true";
import { default as loginKZisD1zVNGMeta } from "/vercel/path0/apps/app/pages/login.vue?macro=true";
import { default as commissionszPCAhcuXyXMeta } from "/vercel/path0/apps/app/pages/mangusta/commissions.vue?macro=true";
import { default as contractu0DQ3fLLRnMeta } from "/vercel/path0/apps/app/pages/mangusta/contract.vue?macro=true";
import { default as indexMrvh3RD32IMeta } from "/vercel/path0/apps/app/pages/mangusta/index.vue?macro=true";
import { default as indexo56WMJV93vMeta } from "/vercel/path0/apps/app/pages/masterclass/index.vue?macro=true";
import { default as _91id_93G1rQcbTKxzMeta } from "/vercel/path0/apps/app/pages/masterclass/live/[id].vue?macro=true";
import { default as _91id_93bLurU9nWWDMeta } from "/vercel/path0/apps/app/pages/masterclass/video/[id].vue?macro=true";
import { default as my_45postsmj7Gv67eurMeta } from "/vercel/path0/apps/app/pages/my-posts.vue?macro=true";
import { default as _91id_93ZTnJyQVnIAMeta } from "/vercel/path0/apps/app/pages/order/success/[id].vue?macro=true";
import { default as password_45resetFh9f2qf6ULMeta } from "/vercel/path0/apps/app/pages/password-reset.vue?macro=true";
import { default as policy1xQvbIklUcMeta } from "/vercel/path0/apps/app/pages/policy.vue?macro=true";
import { default as index3X05g58QxyMeta } from "/vercel/path0/apps/app/pages/redeem-gift/index.vue?macro=true";
import { default as indexrkoBJwHK4iMeta } from "/vercel/path0/apps/app/pages/reminders/index.vue?macro=true";
import { default as rulesF3oaWZzasCMeta } from "/vercel/path0/apps/app/pages/rules.vue?macro=true";
import { default as billingHKgE7A2zNlMeta } from "/vercel/path0/apps/app/pages/settings/billing.vue?macro=true";
import { default as index4vywSk75SUMeta } from "/vercel/path0/apps/app/pages/settings/index.vue?macro=true";
import { default as _91id_93orxyWogMteMeta } from "/vercel/path0/apps/app/pages/settings/invoices/[id].vue?macro=true";
import { default as indexEkVLdg2kvNMeta } from "/vercel/path0/apps/app/pages/settings/invoices/index.vue?macro=true";
import { default as passwordO0XIjzhwpSMeta } from "/vercel/path0/apps/app/pages/settings/password.vue?macro=true";
import { default as securityoPL0QVoAN3Meta } from "/vercel/path0/apps/app/pages/settings/security.vue?macro=true";
import { default as shippingbL0KzaGteaMeta } from "/vercel/path0/apps/app/pages/settings/shipping.vue?macro=true";
import { default as settingsI0axH6LaSYMeta } from "/vercel/path0/apps/app/pages/settings.vue?macro=true";
import { default as shopL8z762DFfnMeta } from "/vercel/path0/apps/app/pages/shop.vue?macro=true";
import { default as signupsBMmaCIExZMeta } from "/vercel/path0/apps/app/pages/signup.vue?macro=true";
import { default as indexxi2Ya8TpeIMeta } from "/vercel/path0/apps/app/pages/tutor/index.vue?macro=true";
import { default as tutorOSgacyJZn9Meta } from "/vercel/path0/apps/app/pages/tutor/tutor.vue?macro=true";
import { default as indexIKVuTYpZpdMeta } from "/vercel/path0/apps/app/pages/watchlist/index.vue?macro=true";
import { default as work_45in_45progressvXNDxlLJCIMeta } from "/vercel/path0/apps/app/pages/work-in-progress.vue?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64 } from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kbwqvznrvxnnvxa5u7auhj6mca/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: __vid_45player_454145172x1b2sCVaWPMeta?.name ?? "__vid-player-4145172",
    path: __vid_45player_454145172x1b2sCVaWPMeta?.path ?? "/__vid-player-4145172",
    meta: __vid_45player_454145172x1b2sCVaWPMeta || {},
    alias: __vid_45player_454145172x1b2sCVaWPMeta?.alias || [],
    redirect: __vid_45player_454145172x1b2sCVaWPMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/__vid-player-4145172.vue").then(m => m.default || m)
  },
  {
    name: _64_91username_93nYiabfff0XMeta?.name ?? "@username",
    path: _64_91username_93nYiabfff0XMeta?.path ?? "/@:username()",
    meta: _64_91username_93nYiabfff0XMeta || {},
    alias: _64_91username_93nYiabfff0XMeta?.alias || [],
    redirect: _64_91username_93nYiabfff0XMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/@[username].vue").then(m => m.default || m)
  },
  {
    name: indexmnGud9ftpqMeta?.name ?? "academy-id-lessonShortCode",
    path: indexmnGud9ftpqMeta?.path ?? "/academy/:id()/:lessonShortCode?",
    meta: indexmnGud9ftpqMeta || {},
    alias: indexmnGud9ftpqMeta?.alias || [],
    redirect: indexmnGud9ftpqMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/academy/[id]/[[lessonShortCode]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexwsXC69kIz1Meta?.name ?? "academy-academy-free-lessonShortCode",
    path: indexwsXC69kIz1Meta?.path ?? "/academy/academy-free/:lessonShortCode?",
    meta: indexwsXC69kIz1Meta || {},
    alias: indexwsXC69kIz1Meta?.alias || [],
    redirect: indexwsXC69kIz1Meta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/academy/academy-free/[[lessonShortCode]]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBuJIBdQObBMeta?.name ?? "academy",
    path: indexBuJIBdQObBMeta?.path ?? "/academy",
    meta: indexBuJIBdQObBMeta || {},
    alias: indexBuJIBdQObBMeta?.alias || [],
    redirect: indexBuJIBdQObBMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/academy/index.vue").then(m => m.default || m)
  },
  {
    name: indexQRck1ME8RmMeta?.name ?? "audiobooks",
    path: indexQRck1ME8RmMeta?.path ?? "/audiobooks",
    meta: indexQRck1ME8RmMeta || {},
    alias: indexQRck1ME8RmMeta?.alias || [],
    redirect: indexQRck1ME8RmMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/audiobooks/index.vue").then(m => m.default || m)
  },
  {
    name: indextu4CDtYlcqMeta?.name ?? "bookmarks",
    path: indextu4CDtYlcqMeta?.path ?? "/bookmarks",
    meta: indextu4CDtYlcqMeta || {},
    alias: indextu4CDtYlcqMeta?.alias || [],
    redirect: indextu4CDtYlcqMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/bookmarks/index.vue").then(m => m.default || m)
  },
  {
    name: indexV73INH7A7JMeta?.name ?? "checkout-id",
    path: indexV73INH7A7JMeta?.path ?? "/checkout/:id()",
    meta: indexV73INH7A7JMeta || {},
    alias: indexV73INH7A7JMeta?.alias || [],
    redirect: indexV73INH7A7JMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/checkout/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexny6S7OVUSLMeta?.name ?? "community-postSlug",
    path: indexny6S7OVUSLMeta?.path ?? "/community/:postSlug()",
    meta: indexny6S7OVUSLMeta || {},
    alias: indexny6S7OVUSLMeta?.alias || [],
    redirect: indexny6S7OVUSLMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/community/[postSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVMmtOk7YyQMeta?.name ?? "community",
    path: indexVMmtOk7YyQMeta?.path ?? "/community",
    meta: indexVMmtOk7YyQMeta || {},
    alias: indexVMmtOk7YyQMeta?.alias || [],
    redirect: indexVMmtOk7YyQMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: company_45valueskPLuH9F0aZMeta?.name ?? "company-values",
    path: company_45valueskPLuH9F0aZMeta?.path ?? "/company-values",
    meta: company_45valueskPLuH9F0aZMeta || {},
    alias: company_45valueskPLuH9F0aZMeta?.alias || [],
    redirect: company_45valueskPLuH9F0aZMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/company-values.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyw0FhKBnoy0Meta?.name ?? "cookie-policy",
    path: cookie_45policyw0FhKBnoy0Meta?.path ?? "/cookie-policy",
    meta: cookie_45policyw0FhKBnoy0Meta || {},
    alias: cookie_45policyw0FhKBnoy0Meta?.alias || [],
    redirect: cookie_45policyw0FhKBnoy0Meta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexXK0uj0kBiVMeta?.name ?? "course-id-lessonShortCode",
    path: indexXK0uj0kBiVMeta?.path ?? "/course/:id()/:lessonShortCode?",
    meta: indexXK0uj0kBiVMeta || {},
    alias: indexXK0uj0kBiVMeta?.alias || [],
    redirect: indexXK0uj0kBiVMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/course/[id]/[[lessonShortCode]]/index.vue").then(m => m.default || m)
  },
  {
    name: email_45confirmationHBxEjS2CxmMeta?.name ?? "email-confirmation",
    path: email_45confirmationHBxEjS2CxmMeta?.path ?? "/email-confirmation",
    meta: email_45confirmationHBxEjS2CxmMeta || {},
    alias: email_45confirmationHBxEjS2CxmMeta?.alias || [],
    redirect: email_45confirmationHBxEjS2CxmMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/email-confirmation.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordlKQlRzReh0Meta?.name ?? "forgot-password",
    path: forgot_45passwordlKQlRzReh0Meta?.path ?? "/forgot-password",
    meta: forgot_45passwordlKQlRzReh0Meta || {},
    alias: forgot_45passwordlKQlRzReh0Meta?.alias || [],
    redirect: forgot_45passwordlKQlRzReh0Meta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexc52TVkaZdsMeta?.name ?? "leaderboard",
    path: indexc52TVkaZdsMeta?.path ?? "/leaderboard",
    meta: indexc52TVkaZdsMeta || {},
    alias: indexc52TVkaZdsMeta?.alias || [],
    redirect: indexc52TVkaZdsMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/leaderboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexsPG2OfC3nIMeta?.name ?? "lessons",
    path: indexsPG2OfC3nIMeta?.path ?? "/lessons",
    meta: indexsPG2OfC3nIMeta || {},
    alias: indexsPG2OfC3nIMeta?.alias || [],
    redirect: indexsPG2OfC3nIMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: loginKZisD1zVNGMeta?.name ?? "login",
    path: loginKZisD1zVNGMeta?.path ?? "/login",
    meta: loginKZisD1zVNGMeta || {},
    alias: loginKZisD1zVNGMeta?.alias || [],
    redirect: loginKZisD1zVNGMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: commissionszPCAhcuXyXMeta?.name ?? "mangusta-commissions",
    path: commissionszPCAhcuXyXMeta?.path ?? "/mangusta/commissions",
    meta: commissionszPCAhcuXyXMeta || {},
    alias: commissionszPCAhcuXyXMeta?.alias || [],
    redirect: commissionszPCAhcuXyXMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/mangusta/commissions.vue").then(m => m.default || m)
  },
  {
    name: contractu0DQ3fLLRnMeta?.name ?? "mangusta-contract",
    path: contractu0DQ3fLLRnMeta?.path ?? "/mangusta/contract",
    meta: contractu0DQ3fLLRnMeta || {},
    alias: contractu0DQ3fLLRnMeta?.alias || [],
    redirect: contractu0DQ3fLLRnMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/mangusta/contract.vue").then(m => m.default || m)
  },
  {
    name: indexMrvh3RD32IMeta?.name ?? "mangusta",
    path: indexMrvh3RD32IMeta?.path ?? "/mangusta",
    meta: indexMrvh3RD32IMeta || {},
    alias: indexMrvh3RD32IMeta?.alias || [],
    redirect: indexMrvh3RD32IMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/mangusta/index.vue").then(m => m.default || m)
  },
  {
    name: indexo56WMJV93vMeta?.name ?? "masterclass",
    path: indexo56WMJV93vMeta?.path ?? "/masterclass",
    meta: indexo56WMJV93vMeta || {},
    alias: indexo56WMJV93vMeta?.alias || [],
    redirect: indexo56WMJV93vMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/masterclass/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G1rQcbTKxzMeta?.name ?? "masterclass-live-id",
    path: _91id_93G1rQcbTKxzMeta?.path ?? "/masterclass/live/:id()",
    meta: _91id_93G1rQcbTKxzMeta || {},
    alias: _91id_93G1rQcbTKxzMeta?.alias || [],
    redirect: _91id_93G1rQcbTKxzMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/masterclass/live/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bLurU9nWWDMeta?.name ?? "masterclass-video-id",
    path: _91id_93bLurU9nWWDMeta?.path ?? "/masterclass/video/:id()",
    meta: _91id_93bLurU9nWWDMeta || {},
    alias: _91id_93bLurU9nWWDMeta?.alias || [],
    redirect: _91id_93bLurU9nWWDMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/masterclass/video/[id].vue").then(m => m.default || m)
  },
  {
    name: my_45postsmj7Gv67eurMeta?.name ?? "my-posts",
    path: my_45postsmj7Gv67eurMeta?.path ?? "/my-posts",
    meta: my_45postsmj7Gv67eurMeta || {},
    alias: my_45postsmj7Gv67eurMeta?.alias || [],
    redirect: my_45postsmj7Gv67eurMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/my-posts.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZTnJyQVnIAMeta?.name ?? "order-success-id",
    path: _91id_93ZTnJyQVnIAMeta?.path ?? "/order/success/:id()",
    meta: _91id_93ZTnJyQVnIAMeta || {},
    alias: _91id_93ZTnJyQVnIAMeta?.alias || [],
    redirect: _91id_93ZTnJyQVnIAMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/order/success/[id].vue").then(m => m.default || m)
  },
  {
    name: password_45resetFh9f2qf6ULMeta?.name ?? "password-reset",
    path: password_45resetFh9f2qf6ULMeta?.path ?? "/password-reset",
    meta: password_45resetFh9f2qf6ULMeta || {},
    alias: password_45resetFh9f2qf6ULMeta?.alias || [],
    redirect: password_45resetFh9f2qf6ULMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: policy1xQvbIklUcMeta?.name ?? "policy",
    path: policy1xQvbIklUcMeta?.path ?? "/policy",
    meta: policy1xQvbIklUcMeta || {},
    alias: policy1xQvbIklUcMeta?.alias || [],
    redirect: policy1xQvbIklUcMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: index3X05g58QxyMeta?.name ?? "redeem-gift",
    path: index3X05g58QxyMeta?.path ?? "/redeem-gift",
    meta: index3X05g58QxyMeta || {},
    alias: index3X05g58QxyMeta?.alias || [],
    redirect: index3X05g58QxyMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/redeem-gift/index.vue").then(m => m.default || m)
  },
  {
    name: indexrkoBJwHK4iMeta?.name ?? "reminders",
    path: indexrkoBJwHK4iMeta?.path ?? "/reminders",
    meta: indexrkoBJwHK4iMeta || {},
    alias: indexrkoBJwHK4iMeta?.alias || [],
    redirect: indexrkoBJwHK4iMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/reminders/index.vue").then(m => m.default || m)
  },
  {
    name: rulesF3oaWZzasCMeta?.name ?? "rules",
    path: rulesF3oaWZzasCMeta?.path ?? "/rules",
    meta: rulesF3oaWZzasCMeta || {},
    alias: rulesF3oaWZzasCMeta?.alias || [],
    redirect: rulesF3oaWZzasCMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/rules.vue").then(m => m.default || m)
  },
  {
    name: settingsI0axH6LaSYMeta?.name ?? undefined,
    path: settingsI0axH6LaSYMeta?.path ?? "/settings",
    meta: settingsI0axH6LaSYMeta || {},
    alias: settingsI0axH6LaSYMeta?.alias || [],
    redirect: settingsI0axH6LaSYMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: billingHKgE7A2zNlMeta?.name ?? "settings-billing",
    path: billingHKgE7A2zNlMeta?.path ?? "billing",
    meta: billingHKgE7A2zNlMeta || {},
    alias: billingHKgE7A2zNlMeta?.alias || [],
    redirect: billingHKgE7A2zNlMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: index4vywSk75SUMeta?.name ?? "settings",
    path: index4vywSk75SUMeta?.path ?? "",
    meta: index4vywSk75SUMeta || {},
    alias: index4vywSk75SUMeta?.alias || [],
    redirect: index4vywSk75SUMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93orxyWogMteMeta?.name ?? "settings-invoices-id",
    path: _91id_93orxyWogMteMeta?.path ?? "invoices/:id()",
    meta: _91id_93orxyWogMteMeta || {},
    alias: _91id_93orxyWogMteMeta?.alias || [],
    redirect: _91id_93orxyWogMteMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEkVLdg2kvNMeta?.name ?? "settings-invoices",
    path: indexEkVLdg2kvNMeta?.path ?? "invoices",
    meta: indexEkVLdg2kvNMeta || {},
    alias: indexEkVLdg2kvNMeta?.alias || [],
    redirect: indexEkVLdg2kvNMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: passwordO0XIjzhwpSMeta?.name ?? "settings-password",
    path: passwordO0XIjzhwpSMeta?.path ?? "password",
    meta: passwordO0XIjzhwpSMeta || {},
    alias: passwordO0XIjzhwpSMeta?.alias || [],
    redirect: passwordO0XIjzhwpSMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: securityoPL0QVoAN3Meta?.name ?? "settings-security",
    path: securityoPL0QVoAN3Meta?.path ?? "security",
    meta: securityoPL0QVoAN3Meta || {},
    alias: securityoPL0QVoAN3Meta?.alias || [],
    redirect: securityoPL0QVoAN3Meta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/security.vue").then(m => m.default || m)
  },
  {
    name: shippingbL0KzaGteaMeta?.name ?? "settings-shipping",
    path: shippingbL0KzaGteaMeta?.path ?? "shipping",
    meta: shippingbL0KzaGteaMeta || {},
    alias: shippingbL0KzaGteaMeta?.alias || [],
    redirect: shippingbL0KzaGteaMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/settings/shipping.vue").then(m => m.default || m)
  }
]
  },
  {
    name: shopL8z762DFfnMeta?.name ?? "shop",
    path: shopL8z762DFfnMeta?.path ?? "/shop",
    meta: shopL8z762DFfnMeta || {},
    alias: shopL8z762DFfnMeta?.alias || [],
    redirect: shopL8z762DFfnMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: signupsBMmaCIExZMeta?.name ?? "signup",
    path: signupsBMmaCIExZMeta?.path ?? "/signup",
    meta: signupsBMmaCIExZMeta || {},
    alias: signupsBMmaCIExZMeta?.alias || [],
    redirect: signupsBMmaCIExZMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indexxi2Ya8TpeIMeta?.name ?? "tutor",
    path: indexxi2Ya8TpeIMeta?.path ?? "/tutor",
    meta: indexxi2Ya8TpeIMeta || {},
    alias: indexxi2Ya8TpeIMeta?.alias || [],
    redirect: indexxi2Ya8TpeIMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/tutor/index.vue").then(m => m.default || m)
  },
  {
    name: tutorOSgacyJZn9Meta?.name ?? "tutor-tutor",
    path: tutorOSgacyJZn9Meta?.path ?? "/tutor/tutor",
    meta: tutorOSgacyJZn9Meta || {},
    alias: tutorOSgacyJZn9Meta?.alias || [],
    redirect: tutorOSgacyJZn9Meta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/tutor/tutor.vue").then(m => m.default || m)
  },
  {
    name: indexIKVuTYpZpdMeta?.name ?? "watchlist",
    path: indexIKVuTYpZpdMeta?.path ?? "/watchlist",
    meta: indexIKVuTYpZpdMeta || {},
    alias: indexIKVuTYpZpdMeta?.alias || [],
    redirect: indexIKVuTYpZpdMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/watchlist/index.vue").then(m => m.default || m)
  },
  {
    name: work_45in_45progressvXNDxlLJCIMeta?.name ?? "work-in-progress",
    path: work_45in_45progressvXNDxlLJCIMeta?.path ?? "/work-in-progress",
    meta: work_45in_45progressvXNDxlLJCIMeta || {},
    alias: work_45in_45progressvXNDxlLJCIMeta?.alias || [],
    redirect: work_45in_45progressvXNDxlLJCIMeta?.redirect,
    component: () => import("/vercel/path0/apps/app/pages/work-in-progress.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.path ?? "/home",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.path ?? "/community/rules",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64
  },
  {
    name: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.name ?? undefined,
    path: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.path ?? "/community/company-values",
    meta: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta || {},
    alias: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.alias || [],
    redirect: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64Meta?.redirect,
    component: nuxt_643_4611_462__64opentelemetry_43api_641_468_460__64parcel_43watcher_642_464_461__64types_43node_6420_4612hangnN5b64
  }
]