export const appRootId = "app"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Scuola per Ricchi"},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"preconnect","href":"https://rsms.me/"},{"rel":"stylesheet","href":"https://rsms.me/inter/inter.css"}],"style":[],"script":[{"src":"https://js.stripe.com/v3/"},{"src":"https://www.googletagmanager.com/gtag/js?id=AW-620905753","async":true},{"src":"https://player.vimeo.com/api/player.js"},{"src":"https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"},{"src":"https://assets.mediadelivery.net/playerjs/player-0.1.0.min.js"}],"noscript":[],"title":"Scuola per Ricchi"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#app'

export const viewTransition = false